<template>
    <v-card
      height="100%"
      color="backgroundColor"
    >
      <UserCleanTab />
    </v-card>
  </template>
  
  <script>
  import UserCleanTab from '../components/AdminVueComponent/UserCleanComponent/UserCleanTab.vue';
  export default {
    components: {
      UserCleanTab,
    },
  };
  </script>
  