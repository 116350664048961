<template>
    <v-row v-if="!fetchingYear" justify="center" class="ma-0 pa-0">
        <v-col cols="12" class="my-4">
            <h1 class="text-h4 primary--text text-center">Affectation d'ouvrages</h1>
        </v-col>

        <v-col cols="12" sm="8" md="8">
            <v-card>
                <v-stepper alt-labels v-model="currentStep">
                    <v-stepper-header>
                        <v-stepper-step step="1">Paramétrage</v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="2">Confirmation</v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="3">Récapitulatif</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-form lazy-validation ref="parametrage">
                                <v-select v-model="anneeScolaire" :items="anneesScolaires" label="Année scolaire"
                                    class="pa-1" clearable :rules="[() => !!anneeScolaire || 'Ce champ est requis']"
                                    required></v-select>

                                <v-select v-model="status" :items="statuts"
                                    label="Statut des utilisateurs" class="pa-1" clearable></v-select>
                                <v-select v-model="role" :rules="[() => !!role]" :items="roles"
                                    label="Rôle des utilisateurs" class="pa-1" clearable multiple></v-select>

                                <v-btn name="Rechercher" height="44px" color="primary" dark
                                    class="AccessButton pl-3 pr-3" tile :loading="loading" elevation="0" fab
                                    @click="validate" width="180">
                                    Rechercher
                                    <v-spacer />
                                    <v-icon class="ml-5"> mdi-magnify</v-icon>
                                </v-btn>
                            </v-form>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <div v-if="!loading">
                                <p>
                                    {{ thoericalCount }} utilisateurs sont éligibles à la suppression en utilisant ces
                                    paramètres, êtes vous sur de vouloir procéder ?
                                    Cette action est irréversible.
                                </p>
    
                                <v-btn name="Suivant" height="44px" color="primary" dark class="AccessButton mt-3 mr-3" tile
                                    elevation="0" :loading="loading" @click="currentStep--" fab width="180">
                                    Retour
                                </v-btn>
    
                                <v-btn name="Suivant" height="44px" color="secondary" dark class="AccessButton mt-3 mr-3"
                                    tile elevation="0" fab :loading="loading" @click="proceedCleaning" width="180">
                                    Suivant
                                </v-btn>
                            </div>
                            <div class="d-flex justify-center" v-else>
                                <v-progress-circular size="100" color="#fb4d61" indeterminate></v-progress-circular>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <div class="d-flex flex-column align-center" v-if="!loading">
                                <p>Action réussie</p>
                                <p>{{ realCount }} Utilisateurs supprimés</p>

                                <div class="d-flex justify-start" style="width: 100%">
                                    <v-btn name="Suivant" height="44px" color="primary" dark class="AccessButton mt-3 mr-3" tile
                                        elevation="0" :loading="loading" @click="currentStep--" fab width="180">
                                        Retour
                                    </v-btn>
        
                                    <v-btn name="Suivant" height="44px" color="secondary" dark class="AccessButton mt-3 mr-3"
                                        tile elevation="0" fab @click="goResumer" width="180">
                                        Suivant
                                    </v-btn>
                                </div>
                            </div>
                            <div class="d-flex justify-center" v-else>
                                <v-progress-circular size="100" color="#fb4d61" indeterminate></v-progress-circular>
                            </div>
                        </v-stepper-content>

                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </v-col>
    </v-row>
    <v-row align="center" style="height: 80vh" justify="center" v-else>
        <v-progress-circular size="100" color="#fb4d61" indeterminate></v-progress-circular>
    </v-row>
</template>

<script>
import AdminService from "@/services/AdminService";
import AnneeService from "@/services/AnneeService.js";

export default {
    name: "UserCleanTab",
    data: () => ({
        currentStep: 1,
        pageInfo: {
            page: 1,
            pageCount: 0,
            totalItems: 0,
            itemsPerPageOptions: [5, 10, 25, 50, 100],
            itemsPerPage: 10,
            itemsPerPageLabel: "Lignes par page",
            sortDesc: [],
            sortBy: [],
        },

        thoericalCount: 0,
        realCount: 0,

        status: null,
        statuts: ["Actif", "Inactif"],

        role: null,
        roles: ["ELEVE", "PROF"],

        loading: false,
        fetchingYear: true,

        anneeScolaire: undefined,
        anneesScolaires: undefined,

    }),
    mounted() {
        this.initSchoolYear();
    },
    methods: {
        initSchoolYear() {
            AnneeService.fetchCurrentAnneeInfo().then(anneeInfo => {
                const anneeScolaire = anneeInfo.data.scolaire;
                this.anneeScolaire = anneeScolaire.code - 101
                this.anneesScolaires = [anneeScolaire.code - 202, anneeScolaire.code - 101]
            }).catch(() => {
                this.anneeScolaire = parseInt(process.env.VUE_APP_DEFAULT_SCHOOL_YEAR)
                this.anneesScolaires = [this.anneeScolaire - 202, this.anneeScolaire - 101]
            }).finally(() => {
                this.fetchingYear = false
            })
        },
        validate() {
            if (this.currentStep === 1) {
                console.log(this.$refs["parametrage"].validate())
                if (this.$refs["parametrage"].validate()) {
                    this.currentStep++;
                    this.fetchUsers()
                }
            }
        },

        fetchUsers() {
            this.loading = true
            var promise
            if (this.status === null) {
                promise = AdminService.countUserForClean(
                    this.anneeScolaire,
                    null,
                    this.role
                )
            } else {
                promise = AdminService.countUserForClean(
                    this.anneeScolaire,
                    this.status === 'Actif',
                    this.role
                )
            }
            promise.then((result) => {
                this.thoericalCount = result.data.affectedRows
            })
            .finally(() => this.loading = false)
        },
        proceedCleaning() {
            this.currentStep++
            this.loading = true
            var promise
            if (this.status === null) {
                promise = AdminService.cleanUsers(
                    this.anneeScolaire,
                    null,
                    this.role
                )
            } else {
                promise = AdminService.cleanUsers(
                    this.anneeScolaire,
                    this.status === 'Actif',
                    this.role
                )
            }
            promise.then((result) => {
                this.realCount = result.data.affectedRows
            })
            .finally(() => this.loading = false)
        },

        goResumer() {
            this.$router.push({name: "DashboardAdmin"})
        },
    },
};
</script>

<style scoped></style>